import React, { useContext } from "react"

import { Container, Row, Col } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"

import GlobalContext from "../contexts/GlobalContext"
import { slugHome, slugHonors } from "../utils/slugs"

import PageWrapper from "../components/PageWrapper"
import { Section, Title, Box } from "../components/Core"
import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"
import bgImg from "../assets/images/bg3.jpg"

const HonorPage = ({ data }) => {
  const gContext = useContext(GlobalContext)

  const honor = data.prismicHonor

  const homeData = data.prismicHome?.data

  const { btn_home } = data.prismicCommon?.data
  return (
    <>
      <GatsbySeo
        title={honor.data.meta_title?.text}
        description={honor.data.meta_description?.text}
      />
      <PageWrapper>
        <Hero bgImg={honor.data.cover_image.url || bgImg}>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                {honor.data.title && (
                  <Title variant="hero" color="light">
                    {honor.data.title.text}
                  </Title>
                )}

                <Box mt={4}>
                  <div className="d-flex my-3 justify-content-center">
                    <div className="text-white font-weight-bold">
                      {">>"}
                      <Link
                        to={slugHome(gContext.currentLang)}
                        className="text-white font-weight-bold"
                      >
                        {btn_home ? btn_home.text : "Home"}
                      </Link>
                    </div>
                    <div className="text-white font-weight-bold  ml-2">
                      {">>"}
                      <Link
                        to={slugHonors(gContext.currentLang)}
                        className="text-white font-weight-bold"
                      >
                        {homeData?.honor_title.text
                          ? homeData?.honor_title.text
                          : "Company Honor"}
                      </Link>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </Hero>
        <Section
          pt={["45px !important", null, null, "60px !important"]}
          className="pb-5"
        >
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="9">
                <Box mb={4}>
                  <div className="d-flex my-3 justify-content-left">
                    <div className="font-weight-bold">
                      {">>"}
                      <Link
                        to={slugHome(gContext.currentLang)}
                        className="breadcrumb-link"
                      >
                        {btn_home && btn_home.text.length > 0
                          ? btn_home.text
                          : "Home"}
                      </Link>
                    </div>
                    <div className="font-weight-bold ml-2">
                      <div>
                        {" >> "}
                        <Link
                          to={slugHonors(gContext.currentLang)}
                          className="breadcrumb-link"
                        >
                          {homeData?.honor_title.text}
                        </Link>
                      </div>
                    </div>
                    <div className="font-weight-bold ml-2">
                      <div>
                        {" >> "}
                        <a
                          href="#"
                          className="breadcrumb-link"
                          onClick={e => {
                            e.preventDefault()
                          }}
                        >
                          {honor.data.title.text}
                        </a>
                      </div>
                    </div>
                  </div>
                </Box>

                <SimpleReactLightbox>
                  <SRLWrapper>
                    <Row>
                      {honor.data.certificates.length > 0 &&
                        honor.data.certificates.map(
                          ({ image }, index) =>
                            image && (
                              <Col lg="4" key={index} className="mb-4">
                                <Img fluid={image.fluid} alt={""} />
                              </Col>
                            )
                        )}
                    </Row>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </Col>
              <Col lg="3">
                <ProductAccordion />
                <ProductSlider />
              </Col>
            </Row>
          </Container>
        </Section>
        <div id="support">
          <Support />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </PageWrapper>
    </>
  )
}
export default HonorPage

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismicHonor(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }

        title {
          text
        }
        cover_image {
          url
        }
        year
        certificates {
          image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }

    prismicHomePage(lang: { eq: $lang }) {
      lang
      data {
        honor_title {
          text
        }
      }
    }

    prismicCommon(lang: { eq: $lang }) {
      lang
      data {
        btn_back {
          text
        }
        btn_home {
          text
        }
      }
    }
  }
`
